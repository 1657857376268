@import "~@ui/styles/tools";

.container {
    display: flex;
    gap: 16px;
}

.input {
    width: 400px;
    // height: 110px;
    
    @include media-desktop-xm {
        width: 275px;
    }
}

.buttonContainer {
    margin: 0;
    padding: 0;
    border: none;
}

.input-component {
    height: 65px;
    background: $color-off-white;

    /* Disable browser autocomplete styles */
    :global(input:-webkit-autofill),
    :global(input:-webkit-autofill:hover), 
    :global(input:-webkit-autofill:focus) {
        border: inherit !important;
        -webkit-text-fill-color: inherit !important;
        box-shadow: 0 0 0 1000px #fff inset !important;
        transition: inherit !important;
    }
}

.button--with-input {
    height: 65px;
    margin-top: -1px;
}

.button--with-input__text {
    font-size: $font-size--xlarge;
    
    @media screen and (max-width: 1825px) {
        font-size: $font-size--large;
    }

    @include media-tablet {
        font-size: $font-size--xsmall;
    }
}

@include media-mobile {
    .container {
        flex-direction: column;
        align-items: flex-start;
        gap: 35px;
    }

    .input {
        width: 100%;
    }

    .delimiter {
        display: none;
    }

    .button {
        width: 100%;
    }

    .button--with-input__text {
        font-size: $font-size--normal;
    }
}